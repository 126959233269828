<template>
  <div>
    <a-input :disabled="disabled" v-model="selectNames" placeholder="请选择商户" allowClear read-only>
      <a-button type="link" size="small" icon="search" @click="show()" slot="addonAfter" :disabled="disabled">
      </a-button>
    </a-input>

    <a-modal :visible="visible" width="60%" title="选择商户" centered @cancel="visible = false" @ok="confirm()">
      <a-layout>
        <div class="content-header">
          <a-affix :offset-top="0" style="width: 100%;">
            <a-row :gutter="15">
              <a-col span="6">
                <a-input v-model="param.keyword" allowClear search @pressEnter="getList" @on-clear="getList"
                         placeholder="关键词查询"></a-input>
              </a-col>
              <a-col span="6">
                <a-select v-model="param.organId" style="width:100%"
                          @change="e => { this.param.depId = ''; organChange(); }" placeholder="根据单位筛选">
                  <a-select-option value="">所有单位</a-select-option>
                  <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                    organ.name }}</a-select-option>
                </a-select>
              </a-col>

            </a-row>
          </a-affix>
        </div>
      </a-layout>

      <a-layout>
        <a-layout-content>
          <a-table :row-selection="rowSelection" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                   :style="{ background: '#FFF' }" size="small" row-key="storeId" border :columns="keys" ref="list"
                   :loading="loading" :data-source="data.records">
            <!--操作-->
            <template slot-scope="row" slot="action">
              <a-button icon="check" type="link" shape="circle" size="small" @click="selectRow(row)"></a-button>
            </template>
          </a-table>
        </a-layout-content>
      </a-layout>
      <a-row>
        <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
          <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
                        :current="data.current" />
        </a-col>
      </a-row>
      <a-back-top />

    </a-modal>
  </div>
</template>

<script>
/**
*  @example <select-staff v-model="formData.managerIds" :names="formData.managers" :organId="formData.organId" multiple></select-staff>
  @event confirm
*/
import utils from '@/common/utils';
import request from '@/config/request';
import apiUtil from '@/common/apiUtil';
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      organList: apiUtil.getOrganList(),
      selectedIds: [],
      selectedList: [],
      selectNames: '',//已选择的用户姓名
      param: {
        page: 1,
        limit: 20,
        keyword: '',
        organId: '',
        status: '正常'
      },
      keys: [],
      is: false,
    };
  },
  methods: {
    ...apiUtil,
    // 选择某一行
    selectRow(row) {
      this.visible = false;
      this.selectedIds = [row.storeId];
      this.selectedList[this.param.page] = [row];
      this.selectNames = row.name;
      this.$emit('confirm', [row], [row.storeId]);
      this.$emit('input', [row.storeId]);
    },
    // 多选确认
    async confirm() {
      let result = [];
      if (!this.is) {
        console.log(this.selectNames.split('|'));
        this.selectedIds.forEach((item, index) => {
          this.selectedList[index] = { storeId: item, name: this.selectNames.split('|')[index] };

        });
        result = this.selectedList;
        // this.selectedList = [];

      }
      else {
        const list = [];
        this.selectedIds.forEach((item, index) => {
          list[index] = { storeId: item, name: this.selectNames.split('|')[index] };
        });
        result = list;
        await result.forEach(async (item) => {
          if (!item.name) {
            await request.get('/platform/store/getStoreName', { storeIds: item.storeId }).then(ret => {
              // console.log(ret.data);
              item.name = ret.data;
            });
          }
        });
      }
      // console.log(result);
      setTimeout(() => {
        const names = [];
        result.forEach(item => {
          names.push(item.name);
        });
        console.log(names, 'names');
        console.log(result, 'result');
        this.selectNames = names.join('|');
        this.visible = false;
        this.$emit('confirm', result, this.selectedIds);
        this.$emit('input', this.selectedIds);
      }, 200);
    },
    show() {
      this.param.organId = this.organId;
      this.doRefresh();
    },
    organChange() {
      this.is = false;
      this.doRefresh();
    },
    doRefresh() {
      this.param.page = 1;
      this.getList();
    },
    getList() {
      // utils.showSpin();
      this.loading = true;
      request.get("platform/store/list", this.param).then(ret => {
        // console.log(ret);
        this.data = ret.data;
        this.visible = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
      this.getList();
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      this.getList();
    },
    //计算初始名称
    namesInit(name) {
      if (!utils.isEmpty(name)) {
        if (typeof name == 'string') {
          this.selectNames = name;
        }
        if (typeof name == 'object') {
          this.selectNames = name.join('|');
        }
      } else {
        this.selectNames = '';
      }
      //如果名称是空的，并且ids已经存在，直接从服务器获取名称
      if (this.selectedIds.length > 0 && this.selectNames == '') {
        request.get('/platform/store/getStoreName', { storeIds: this.selectedIds.join(",") }).then(ret => {
          this.selectNames = ret.data;
        });
      }
    },
    //计算初始值
    valueInit(val) {
      if (!utils.isEmpty(val)) {
        if (typeof val == 'string') {
          const ids_arr = val.split(",");
          const ids_num = [];
          ids_arr.forEach(id => {
            ids_num.push(parseInt(id));
          });
          this.selectedIds = ids_num;
        } else if (typeof val == 'number') {
          this.selectedIds = [val];
        } else {
          this.selectedIds = val;
        }
      } else {
        this.selectedIds = [];
        this.selectNames = '';
      }
    }
  },
  mounted() {
    this.selectedIds = [];
    this.selectedList = [];
    this.selectNames = '';
    this.is = false;
    if (apiUtil.getOrganId() == 0) {
      this.keys = [
        { title: '商户名称', dataIndex: 'name', align: 'left', width: 120, ellipsis: true },
        { title: '所属单位', dataIndex: 'organName', width: 260, ellipsis: true },
        { title: '负责人', dataIndex: 'person', align: 'left', width: 120, ellipsis: true },
        { title: '手机号', dataIndex: 'phone', width: 160, ellipsis: true },
        { title: '地址', dataIndex: 'address', width: 260, ellipsis: true }
      ];
    } else {
      this.keys = [
        { title: '商户名称', dataIndex: 'name', align: 'left', width: 120, ellipsis: true },
        { title: '负责人', dataIndex: 'person', align: 'left', width: 120, ellipsis: true },
        { title: '手机号', dataIndex: 'phone', width: 160, ellipsis: true },
        { title: '地址', dataIndex: 'address', width: 260, ellipsis: true }
      ];
    }
    //初始值
    this.$nextTick(() => {
      this.valueInit(this.value);
      this.namesInit(this.names);
    });
  },
  computed: {
    rowSelection() {
      const { selectedIds } = this;
      return {
        selectedRowKeys: selectedIds,
        type: this.multiple ? 'checkbox' : 'radio',
        onChange: (ids, rows) => {
          this.selectedIds = ids;
          let names = [];
          // const aaa = this.selectNames.split("|");
          // rows.forEach(item => {
          //     names.push(item.name);
          // });

          // const bbb = [...aaa, ...names];
          // const uniqueArr = bbb.reduce((result, current) => {
          //     if (!result.includes(current)) {
          //         if (!aaa.find(value => value == current)) {
          //             result.push(current);
          //         }

          //     }
          //     return result;
          // }, []);
          // this.selectNames = uniqueArr.join('|');
          this.selectedList[this.param.page] = rows;
          this.is = true;
        }
      };
    }
  },
  watch: {
    value(val) {
      this.valueInit(val);
    },
    names(name) {
      this.namesInit(name);
    },
    organId(v) {
      this.param.organId = v;
      // this.selectedIds = [];
      // this.selectedList = [];
      // this.selectNames = '';
      // this.confirm();
    }
  },
  props: {
    //是否可多选
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    //已选中的人员ID
    value: {
      type: Array | Number | String,
      default: () => {
        return [];
      }
    },
    //已选择人员姓名
    names: {
      type: Array | String,
      default: () => {
        return [];
      }
    },

    disabled: {
      type: Boolean,
      default: () => false
    },
    organId: {
      type: String | Number,
      default: () => {
        return apiUtil.getOrganId();
      }
    }
  }
};
</script>

<style></style>