<template>
  <div>
    <p class="desc">选择能发起该审批的人员/部门/角色，全不选则默认开放给所有人</p>
    <a-form>
      <a-form-model-item label="授权范围(部门)">
        <select-depart v-model="depIds" multiple @change="selectDepart" isAll></select-depart>
      </a-form-model-item>
      <a-form-model-item label="授权范围(角色)">
        <select-role v-model="roleIds" multiple @change="selectRole" system isAll></select-role>
      </a-form-model-item>
      <a-form-model-item label="授权范围(员工)">
        <select-staff v-model="staffIds" :names="names" multiple @confirm="selectStaff" isAll></select-staff>
      </a-form-model-item>
      <a-form-model-item label="授权范围(商户)">
        <select-store v-model="storeIds" multiple @confirm="selectStoreId" isAll></select-store>
      </a-form-model-item>
    </a-form>
  </div>
</template>

<script>
import selectStore from '@/components/common/select-store.vue';
import loginVue from '@/views/login.vue';

export default {
  name: "RootConfig",
  components: { selectStore },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      depIds: [],
      roleIds: [],
      staffIds: [],
      formItems: [],
      names: [],
      storeIds: []
    };
  },
  mounted() {
    this.depIds = this.selectDepIds;
    this.roleIds = this.selectRoleIds;
    this.staffIds = this.selectStaffIds;
    this.names = this.selectNames;
    this.storeIds = this.selectStoreIds;
  },
  computed: {
    selectDepIds() {
      return this.config.depIds;
    },
    selectRoleIds() {
      return this.config.roleIds;
    },
    selectStaffIds() {
      return this.config.staffIds;
    },
    selectNames() {
      return this.config.names;
    },
    selectStoreIds() {
      return this.config.storeIds;
    }
  },
  methods: {
    // 选择部门
    selectDepart(ids) {
      this.selectDepIds.forEach((item, i) => { this.selectDepIds.splice(0, this.selectDepIds.length); });
      ids.forEach((id, i) => {
        this.selectDepIds.push(id);
      });
    },
    // 选择角色
    selectRole(ids) {
      this.selectRoleIds.forEach((item, i) => { this.selectRoleIds.splice(0, this.selectRoleIds.length); });
      ids.forEach((id, i) => {
        this.selectRoleIds.push(id);
      });
    },
    // 选择人员
    selectStaff(list) {
      this.selectStaffIds.forEach((item, i) => { this.selectStaffIds.splice(0, this.selectStaffIds.length); });
      let names = [];
      list.forEach((item, i) => {
        this.selectStaffIds.push(item.staffId);
        names.push(item.name);
      });
      this.config.names = names.join("|");
    },
    // 选择商户
    selectStoreId(list) {
      console.log(this.selectStoreIds, "ids");
      this.selectStoreIds.forEach((item, i) => { this.selectStoreIds.splice(0, this.selectStoreIds.length); });
      let names = [];
      list.forEach((item, i) => {
        this.selectStoreIds.push(item.storeId);
        names.push(item.name);
      });
    }

  }
};
</script>

<style lang="less" scoped>
.desc {
  font-size: small;
  color: #8c8c8c;
}

.org-item {
  margin: 5px;
}
</style>
