<template>
  <div>
    <div style="margin-bottom: 20px">
      <p class="item-desc">延时方式</p>
      <a-radio-group v-model="config.type" size="small">
        <a-radio-button label="FIXED">固定时长</a-radio-button>
        <a-radio-button label="AUTO">自动计算</a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="config.type === 'FIXED'">
      <a-input style="width: 180px;" placeholder="时间单位" size="small" type="number" v-model="config.time">
        <a-select style="width: 75px;" v-model="config.unit" slot="append" placeholder="请选择">
          <a-select-option label="天" value="D"></a-select-option>
          <a-select-option label="小时" value="H"></a-select-option>
          <a-select-option label="分钟" value="M"></a-select-option>
        </a-select>
      </a-input>
      <span class="item-desc"> 后进入下一步</span>
    </div>
    <div class="item-desc" v-else>
      <el-time-picker value-format="HH:mm:ss" style="width: 150px;" size="small" v-model="config.dateTime"
        placeholder="任意时间点"></el-time-picker>
      <span class="item-desc"> 后进入下一步</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DelayNodeConfig",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped></style>
