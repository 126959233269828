<template>
  <div :class="{ 'node': true, 'node-error-state': showError }">
    <div :class="{ 'node-body': true, 'error': showError }">
      <div class="node-body-left" @click="$emit('leftMove')" v-if="level > 1">
        <a-icon type="left"></a-icon>
      </div>
      <div class="node-body-main" @click="$emit('selected')">
        <div class="node-body-main-header">
          <span class="title">{{ config.name ? config.name : ('条件' + level) }}</span>
          <span class="level">优先级{{ level }}</span>
          <span class="option">
            <a-tooltip effect="dark" title="复制条件" placement="top">
              <a-icon class="icon" type="copy" @click.stop="$emit('copy')"></a-icon>
            </a-tooltip>
            <a-icon class="icon" type="close" @click.stop="$emit('delNode')"></a-icon>
          </span>
        </div>
        <div class="node-body-main-content">
          <span class="placeholder" v-if="(content || '').trim() === ''">{{ placeholder }}</span>
          <ellipsis hoverTip :row="4" :content="content" v-else />
        </div>
      </div>
      <div class="node-body-right" @click="$emit('rightMove')" v-if="level < size">
        <a-icon type="right"></a-icon>
      </div>
      <div class="node-error" v-if="showError">
        <a-tooltip effect="dark" :title="errorInfo" placement="top-start">
          <a-icon type="warning"></a-icon>
        </a-tooltip>
      </div>
    </div>
    <div class="node-footer">
      <div class="btn">
        <insert-button @insertNode="type => $emit('insertNode', type)"></insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from '@/components/process/process/node/InsertButton.vue'
import ValueType from '@/components/process/process/ConditionVarType'

export default {
  name: "ConditionNode",
  components: { InsertButton },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //索引位置
    level: {
      type: Number,
      default: 1
    },
    //条件数
    size: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ValueType,
      placeholder: '请设置条件',
      errorInfo: '',
      showError: false
    }
  },
  computed: {
    content() {
      const groups = this.config.props.groups;
      // console.log(groups)
      let confitions = []
      groups.forEach(group => {
        let subConditions = []
        group.conditions.forEach(subCondition => {
          let subConditionStr = ''
          // console.log(subCondition.title, subCondition.compare, ValueType.string)
          switch (subCondition.valueType) {
            case "depart":
            case "staff":
            case "role":
              subConditionStr = `${subCondition.title}属于[${String(subCondition.value.length)}个]之一`
              break;
            case "radio":
            case "select":
              if (subCondition.compare == '=') {
                subConditionStr = `${subCondition.title}=${subCondition.options.filter(o => { return o.value == subCondition.value[0] })[0]['label']}`
              }
              if (subCondition.compare == '!=') {
                subConditionStr = `${subCondition.title}!=(${subCondition.options.filter(o => { return o.value == subCondition.value[0] })[0]['label']})`
              }
              break;
            default:
              if (subCondition.value.length < 1) {
                break;
              }
              if (subCondition.compare == '=') {
                subConditionStr = `${subCondition.title}=${subCondition.value[0]}`
              }
              if (subCondition.compare == '<') {
                subConditionStr = `${subCondition.title}<${subCondition.value[0]}`
              }
              if (subCondition.compare == '<=') {
                subConditionStr = `${subCondition.title}≤${subCondition.value[0]}`
              }
              if (subCondition.compare == '>') {
                subConditionStr = `${subCondition.title}>${subCondition.value[0]}`
              }
              if (subCondition.compare == '>=') {
                subConditionStr = `${subCondition.title}≥${subCondition.value[0]}`
              }
              if (subCondition.compare == '!=') {
                subConditionStr = `${subCondition.title}!=${subCondition.value[0]}`
              }
              if (subCondition.compare == 'in') {
                subConditionStr = `${subCondition.title}包含${subCondition.value[0]}`
              }
              break;
          }
          subConditions.push(subConditionStr)
        })
        //根据子条件关系构建描述
        let subConditionsStr = String(subConditions)
          .replaceAll(',', subConditions.length > 1 ?
            (group.groupType === 'AND' ? ') 且 (' : ') 或 (') :
            (group.groupType === 'AND' ? ' 且 ' : ' 或 '))
        confitions.push(subConditions.length > 1 ? `(${subConditionsStr})` : subConditionsStr)
      })
      //构建最终描述
      return String(confitions).replaceAll(',', (this.config.props.groupsType === 'AND' ? ' 且 ' : ' 或 '))
    }
  },
  methods: {
    getNumberConditionContent(subCondition) {
      switch (subCondition.compare) {
        case 'IN':
          return `${subCondition.title}为[${String(subCondition.value)}]中之一`
        case 'B':
          return `${subCondition.value[0]} < ${subCondition.title} < ${subCondition.value[1]}`
        case 'AB':
          return `${subCondition.value[0]} ≤ ${subCondition.title} < ${subCondition.value[1]}`
        case 'BA':
          return `${subCondition.value[0]} < ${subCondition.title} ≤ ${subCondition.value[1]}`
        case 'ABA':
          return `${subCondition.value[0]} ≤ ${subCondition.title} ≤ ${subCondition.value[1]}`
        case '<=':
          return `${subCondition.title} ≤ ${subCondition.value[0]}`
        case '>=':
          return `${subCondition.title} ≥ ${subCondition.value[0]}`
        default:
          return `${subCondition.title}${subCondition.compare}${subCondition.value[0]}`
      }
    },
    //校验数据配置的合法性
    validate() {
      if (this.config.assignedUser && this.config.assignedUser.length > 0) {
        this.showError = false
      } else {
        this.showError = true
        this.errorInfo = '请选择需要抄送的人员'
      }
      return !this.showError
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../static/theme";

.node-error-state {
  .node-body {
    box-shadow: 0px 0px 5px 0px #F56C6C !important;
  }
}

.node {
  padding: 50px 10px;
  width: 220px;

  .node-body {
    // width: 220px;
    cursor: pointer;
    min-height: 80px;
    max-height: 120px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d8d8d8;

    &:hover {

      .node-body-left,
      .node-body-right {
        i {
          display: block !important;
        }
      }

      .node-body-main {

        .level {
          display: none !important;
        }

        .option {
          display: inline-block !important;
        }
      }

      box-shadow: 0px 0px 3px 0px @primary;
    }

    .node-body-left,
    .node-body-right {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;

      i {
        display: none;
      }

      &:hover {
        background-color: #ececec;
      }
    }

    .node-body-left {
      left: 0;
    }

    .node-body-right {
      right: 0;
      top: 0;
    }

    .node-body-main {
      //position: absolute;
      width: 188px;
      margin-left: 17px;
      display: inline-block;

      .node-body-main-header {
        padding: 10px 0px 5px;
        font-size: xx-small;
        position: relative;

        .title {
          color: #15bca3;
        }

        .level {
          position: absolute;
          right: 15px;
          color: #888888;
        }

        .option {
          position: absolute;
          right: 20px;
          display: none;
          font-size: medium;

          .icon {
            color: #888888;
            padding: 0 3px;
            font-size: 12px;
          }
        }
      }

      .node-body-main-content {
        padding: 6px;
        color: #656363;
        font-size: 14px;

        i {
          position: absolute;
          top: 55%;
          right: 10px;
          font-size: medium;
        }

        .placeholder {
          color: #8c8c8c;
        }
      }
    }

    .node-error {
      position: absolute;
      right: -40px;
      top: 20px;
      font-size: 25px;
      color: #F56C6C;
    }
  }

  .node-footer {
    position: relative;

    .btn {
      width: 100%;
      display: flex;
      height: 70px;
      padding: 20px 0 32px;
      justify-content: center;
    }

    /deep/ .a-button {
      height: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #CACACA;
    }
  }
}
</style>
