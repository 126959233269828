<template>
    <div>
        <a-page-header :title="data.name" style="padding:0">
            <template slot="extra">
                <a-button-group style="margin-right: 10px;">
                    <a-button type="primary" @click="saveFlow()">保存</a-button>
                </a-button-group>
                <a-button icon="arrow-left" @click="$emit('back')">返回</a-button>
            </template>
            <a-divider></a-divider>
            <div class="content">
                <div class="main">
                    <a-descriptions size="small" :column="4">
                        <a-descriptions-item label="流程分类">
                            {{ data.categoryName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="使用表单">
                            {{ form.name }}
                            <a @click="$refs.formView.show(form.name, form.content)">预览</a>
                        </a-descriptions-item>
                        <a-descriptions-item label="创建人">
                            {{ data.staffName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="创建时间">
                            {{ data.createTime }}
                        </a-descriptions-item>
                        <a-descriptions-item label="流程说明" :span="4">
                            {{ data.description }}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
            </div>

            <template slot="footer">
                <process-design />
                <!-- <a-tabs default-active-key="design">
                    <a-tab-pane key="design" tab="图形视图">
                        <process-design />
                    </a-tab-pane>
                    <a-tab-pane key="base" tab="列表视图">
                        <a-table :pagination="false" :style="{ background: '#FFF' }" size="small" row-key="flowId" border
                            :columns="cloumn" ref="list" :data-source="flowList">
                            <template slot-scope="row" slot="action">
                                <a-button-group shape="circle" size="small">
                                    <a-tooltip title="设计流程"><a-button type="link" icon="ant-design"></a-button></a-tooltip>
                                    <a-tooltip title="编辑"><a-button type="link" icon="edit"></a-button></a-tooltip>
                                    <a-tooltip title="删除"><a-button type="link" icon="delete"></a-button></a-tooltip>
                                </a-button-group>
                            </template>
                        </a-table>
                    </a-tab-pane>
                </a-tabs> -->
            </template>
        </a-page-header>

        <form-view ref="formView"></form-view>
    </div>
</template>

<script>
import apiUtils from '@/common/apiUtil';
import request from '@/config/request';
import utils from '@/common/utils';
import formView from '../../form/components/form-view.vue';
import ProcessDesign from '@/components/process/process/ProcessDesign.vue';
import { commonUtils } from '@/common/common-utils';
export default {
    components: { formView, ProcessDesign },
    data() {
        return {
            form: {},
            flowList: [],
            formItem: [],
            cloumn: [
                { title: '步骤名称', dataIndex: 'name', align: 'left', width: 300, ellipsis: true },
                // { title: '说明', dataIndex: 'description', align: 'left', ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ]
        }
    },
    mounted() {
        this.form = this.data.form;
        if (!utils.isEmpty(this.form.fields)) {
            this.formItem = JSON.parse(this.form.fields);
        } else {
            this.formItem = [];
        }
        this.$store.commit('loadFormItem', this.formItem);
        this.$store.commit('loadProcess', this.data.process);
    },
    methods: {
        // 保存流程
        saveFlow() {
            const jsonData = {
                approveId: this.data.approveId,
                nodes: []
            };
            const nodeMap = this.$store.state.flow.nodeMap;
            // nodeMap.forEach(item => {
            //     console.log(item.name + ':id=' + item.id + ',parentId=' + item.parentId);
            // })
            // return;
            const nodes = [];
            let validate = true;
            nodeMap.forEach(element => {
                const item = JSON.parse(JSON.stringify(element));
                if (item.branchs) {
                    item.branchs.forEach(branch => {
                        branch.children = null;
                    })
                }
                if (item.type == 'APPROVAL') {
                    const props = item.props;
                    if (props.depIds.length < 1 && props.roleIds.length < 1 && props.staffIds.length < 1) {
                        validate = false;
                    }
                }
                item.children = null
                nodes.push(item);
            });
            if (!validate) {
                utils.error('请选择审批人员或部门'); return;
            }
            jsonData.nodes = nodes;
            utils.showSpin();
            request.post('/platform/flow/save', jsonData).then(ret => {
                utils.success('保存成功');
                this.$emit('success')
            })

        },
    },
    props: {
        approveId: {
            type: Number | String,
            default: () => {
                return '';
            }
        },
        data: {
            type: Object,
            defaullt: { form: {}, process: {} }
        }
    },
}
</script>

<style></style>