<template>
  <div>
    <div class="scale">
      <a-button icon="plus" size="small" @click="scale += 10" :disabled="scale >= 150" shape="circle"></a-button>
      <span>{{ scale }}%</span>
      <a-button icon="minus" size="small" @click="scale -= 10" :disabled="scale <= 40" shape="circle"></a-button>
      <!-- <a-button @click="validate">校验流程</a-button> -->
    </div>
    <div class="design" :style="'transform: scale(' + scale / 100 + ');'">
      <process-tree ref="process-tree" @selectedNode="nodeSelected" />
    </div>
    <a-drawer :visible="showConfig" :width="selectedNode.type === 'CONDITION' ? '600px' : '500px'" direction="rtl"
      :maskClosable="true" @close="() => { showConfig = false; }" destroy-on-close>
      <div slot="title">
        <a-input v-model="selectedNode.name" v-show="showInput" style="width: 300px" @blur="showInput = false"></a-input>
        <a-button type="link" v-show="!showInput" @click="showInput = true" icon="edit">
          {{ selectedNode.name }}
        </a-button>
      </div>
      <div class="node-config-content">
        <node-config />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import ProcessTree from './ProcessTree.vue'
import NodeConfig from './node/config/NodeConfig'

export default {
  name: "ProcessDesign",
  components: { ProcessTree, NodeConfig },
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.flow.selectedNode
    }
  },
  mounted() {

  },
  methods: {
    validate() {
      this.$refs["process-tree"].validateProcess()
    },
    nodeSelected(node) {
      // console.log('配置节点', node)
      if (node.type == 'CONCURRENT') { return; }
      this.showConfig = true
    }
  },
  watch: {
    selectedNode: {
      deep: true,
      handler(node) {
        // console.log("更新")
        this.$refs["process-tree"].nodeDomUpdate(node)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.design {
  // margin-top: 100px;
  display: flex;
  transform-origin: 50% 0px 0px;
}

.scale {
  z-index: 999;
  position: fixed;
  bottom: 40px;
  right: 40px;

  span {
    margin: 0 20px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

.node-config-content {
  padding: 0;
}

/deep/ .el-drawer__body {
  overflow-y: auto;
}
</style>
