<template>
    <div>
        Hello world
    </div>
</template>

<script>
export default {
    name: "concurrent"

}
</script>

<style></style>