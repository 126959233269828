<template>
  <node :title="config.name" :is-root="true" :content="content" @selected="$emit('selected')"
        @insertNode="type => $emit('insertNode', type)" placeholder="所有人" header-bgc="#576a95"
        header-icon="el-icon-user-solid" />
</template>

<script>
import Node from './Node';

export default {
  name: "RootNode",
  components: { Node },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    content() {
      let name = '所有人';
      const list = [];
      if (this.config.props.depIds.length > 0) {
        list.push(this.config.props.depIds.length + '个部门');
      }
      if (this.config.props.roleIds.length > 0) {
        list.push(this.config.props.roleIds.length + '个角色');
      }
      if (this.config.props.staffIds.length > 0) {
        list.push(this.config.props.staffIds.length + '个员工');
      }
      if (this.config.props.storeIds.length > 0) {
        list.push(this.config.props.storeIds.length + '个商户');
      }
      if (list.length > 0) {
        name = '' + list.join("+");
      }
      return name + "可发起流程";
    }
  },
  data() {
    return {
    };
  },
  methods: {}
};
</script>

<style scoped></style>
