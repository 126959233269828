<template>
  <node :title="config.name" :show-error="showError" :content="content" :error-info="errorInfo"
        @selected="$emit('selected')" @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)"
        placeholder="请设置审批人" header-bgc="#ff943e" header-icon="el-icon-s-check" />
</template>

<script>
import utils from '@/common/utils';
import Node from './Node';

export default {
  name: "ApprovalNode",
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { Node },
  data() {
    return {
      showError: false,
      errorInfo: '',
    };
  },
  computed: {
    content() {
      const config = this.config.props;
      if (config.depIds.length < 1 && config.roleIds.length < 1 && config.staffIds.length < 1) {
        return '请指定审批权限';
      }
      let name = '';
      const list = [];
      if (config.depIds.length > 0) {
        list.push(config.depIds.length + '个部门');
      }
      if (config.roleIds.length > 0) {
        list.push(config.roleIds.length + '个角色');
      }
      if (config.staffIds.length > 0) {
        list.push(config.staffIds.length + '个员工');
      }
      console.log(this.config, '111');
      if (config.storeIds.length > 0) {
        list.push(config.storeIds.length + '个商户');
      }
      if (list.length > 0) {
        name = '' + list.join("+");
      }
      return name + '有审批权限';
    }
  },
  methods: {
    getFormItemById(id) {
      return this.$store.state.flow.design.formItems.find(item => item.id === id);
    },
    //校验数据配置的合法性
    validate() {
      try {
        return this.showError = !this[`validate_${this.config.assignedType}`]();
      } catch (e) {
        return true;
      }
    },
    validate_ASSIGN_USER() {
      return true;
    },
    validate_SELF_SELECT() {
      return true;
    },
    validate_LEADER_TOP() {
      return true;
    },
    validate_LEADER() {
      return true;
    },
    validate_ROLE() {
      return true;
    },
    validate_SELF() {
      return true;
    },
    validate_REFUSE() {
      return true;
    },
  }
};
</script>

<style scoped></style>
