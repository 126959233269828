<template>
  <div>
    <a-tabs defaultActiveKey="properties" v-model="active" v-if="name && formConfig.length > 0">
      <a-tab-pane :tab="name" key="properties">
        <component :is="(selectNode.type||'').toLowerCase()" :config="selectNode.props" />
      </a-tab-pane>
      <a-tab-pane tab="表单权限设置" key="permissions">
        <form-authority-config />
      </a-tab-pane>
    </a-tabs>
    <component :is="(selectNode.type||'').toLowerCase()" v-else :config="selectNode.props" />
  </div>
</template>

<script>
import Approval from './ApprovalNodeConfig.vue';
import Condition from './ConditionNodeConfig.vue';
import Delay from './DelayNodeConfig.vue';
import Cc from './CcNodeConfig.vue';
import Trigger from './TriggerNodeConfig.vue';
import FormAuthorityConfig from './FormAuthorityConfig.vue';
import Root from './RootNodeConfig.vue';
import concurrent from './ConCurrentConfig.vue';

export default {
  name: "NodeConfig",
  components: {
    Approval,
    Condition,
    Trigger,
    Delay,
    Root,
    Cc,
    FormAuthorityConfig,
    concurrent
  },
  data() {
    return {
      active: 'properties',
    };
  },
  computed: {
    selectNode() {
      return this.$store.state.flow.selectedNode;
    },
    formConfig() {
      return this.$store.state.flow.design.formItems;
    },
    name() {
      switch (this.selectNode.type) {
        case 'ROOT':
          return '设置发起人';
        case 'APPROVAL':
          return '设置审批人';
        default:
          return null;
      }
    }
  },
  methods: {},
  mounted() {
    console.log(this.selectNode);
  }
};
</script>

<style lang="less" scoped></style>
