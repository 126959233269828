<template>
  <div>
    <a-form :model="config" inline label-width="100px">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="调整优先级" prop="level">
            <a-popover title="拖拽条件调整优先级顺序" width="250" trigger="click">
              <div slot="content" style="width: 250px; min-height:25px">
                <draggable :list="prioritySortList" group="from" :options="sortOption">
                  <div :class="{ 'drag-no-choose': true, 'drag-hover': cd.id === selectedNode.id }"
                    v-for="(cd, index) in prioritySortList" :key="cd.id">
                    <div>{{ cd.name }}</div>
                    <div>优先级 {{ index + 1 }}</div>
                  </div>
                </draggable>
              </div>
              <a-button icon="sort">第{{ nowNodeLeave + 1 }}级</a-button>
            </a-popover>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="条件组关系" label-width="150px">
            <a-radio-group v-model="config.groupsType">
              <a-radio value="AND">且</a-radio>
              <a-radio value="OR">或</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- <a-form-model-item label="条件组表达式">
        <a-input v-model="config.expression" placeholder="输入条件组关系表达式  &为与，|为或" />
        <span class="item-desc">使用表达式构建复杂逻辑，例如: (A & B) | C</span>
      </a-form-model-item> -->
    </a-form>
    <div>
      <a-button type="primary" icon="plus" style="margin: 0 15px 15px 0" round @click="addConditionGroup">
        添加条件组
      </a-button>
      <span class="item-desc">只有必填选项才能作为审批条件</span>
    </div>
    <group-item />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import GroupItem from "./ConditionGroupItemConfig.vue"

export default {
  name: "ConditionNodeConfig",
  components: { draggable, GroupItem },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.flow.selectedNode
    },
    select() {
      return this.config.assignedUser || []
    },
    nowNodeLeave() {
      return this.prioritySortList.indexOf(this.selectedNode)
    },
    //条件节点
    prioritySortList() {
      let node = this.$store.state.flow.nodeMap.get(this.selectedNode.parentId)
      console.log(this.selectedNode.id, node)
      if (node) {
        return node.branchs || []
      }
      return []
    }
  },
  data() {
    return {
      sortOption: {
        animation: 300,
        chosenClass: 'choose',
        scroll: true,
        sort: true
      }
    }
  },
  methods: {
    addConditionGroup() {
      this.config.groups.push({
        cids: [],
        groupType: "OR",
        conditions: []
      })
    },
    closeSelect() {

    },
    selectUser() {
      this.showOrgSelect = true
    },
    selected(select) {
      console.log(select)
      this.showOrgSelect = false
      select.forEach(val => this.select.push(val))
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.choose {
  border-radius: 5px;
  margin-top: 2px;
  background: #f4f4f4;
  border: 1px dashed #1890FF !important;
}

.drag-hover {
  background: #79bbff;
  color: #1890FF
}

.drag-no-choose {
  cursor: move;
  background: #f4f4f4;
  border-radius: 5px;
  position: relative;
  margin-top: 2px;
  padding: 5px 10px;
  border: 1px solid #bcbcbc;
  height: 30px;

  div:nth-child(1) {
    font-size: x-small;
    position: absolute;
    width: 160px;
    left: 10px;
    height: 20px;
    overflow: hidden;
  }

  div:nth-child(2) {
    position: absolute;
    right: 10px;
  }
}
</style>
