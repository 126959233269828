<template>
  <div>
    <div v-for="(group, index) in selectedNode.props.groups" :key="index + '_g'" class="group">
      <div class="group-header">
        <span class="group-name">条件组 {{ groupNames[index] }}</span>
        <div class="group-cp">
          <span>组内条件关系：</span>
          <a-radio-group v-model="group.groupType">
            <a-radio value="AND">且</a-radio>
            <a-radio value="OR">或</a-radio>
          </a-radio-group>
        </div>
        <div class="group-operation">
          <a-popover title="选择审批条件" trigger="click">
            <!-- <div>以下条件将决定具体的审批流程</div>-->
            <div slot="content" style="max-width: 400px;">
              <a-checkbox-group v-model="group.cids" @change="() => { conditionChange(group); }">
                <a-checkbox :value="condition.id" v-for="(condition, cindex) in conditionList" :key="cindex">
                  {{ condition.title }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <a-icon type="plus"></a-icon>
          </a-popover>
          <a-icon type="delete" @click="delGroup(index)"></a-icon>
        </div>
      </div>
      <div class="group-content">
        <p v-if="group.conditions.length === 0">点击右上角 + 为本条件组添加条件 ☝</p>
        <div v-else>
          <!-- {{ group.conditions }} -->
          <a-form :model="group.conditions" ref="condition-form" label-width="100px" layout="vertical"
            labelAlign="left">
            <a-row :gutter="15" v-for="(condition, cindex) in group.conditions" :key="condition.id + '_' + cindex"
              :style="{ padding: '5px 0' }">
              <a-col :span="6">
                <a-input v-model="condition.title" read-only></a-input>
                <!-- {{ condition.title }}-{{ condition.valueType }} -->
              </a-col>
              <a-col :span="5">
                <a-select placeholder="判断符" v-model="condition.compare">
                  <a-select-option label="等于" value="=">=</a-select-option>
                  <a-select-option label="不等于" value="!=">!=</a-select-option>
                  <a-select-option label="包含" value="in" v-if="isIn(condition.valueType)">包含</a-select-option>
                  <a-select-option label="大于" value=">" v-if="isGt(condition.valueType)">&gt;</a-select-option>
                  <a-select-option label="大于等于" value=">=" v-if="isGt(condition.valueType)">≥</a-select-option>
                  <a-select-option label="小于" value="<" v-if="isLt(condition.valueType)"> &lt; </a-select-option>
                  <a-select-option label="小于等于" value="<=" v-if="isLt(condition.valueType)">≤</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="13">
                <template v-if="condition.valueType == 'staff'">
                  <select-staff v-model="condition.value" :multiple="condition.compare == 'in'"></select-staff>
                </template>
                <template v-else-if="condition.valueType == 'role'">
                  <select-role v-model="condition.value" :multiple="condition.compare == 'in'" isAll></select-role>
                </template>
                <template v-else-if="condition.valueType == 'depart'">
                  <select-depart v-model="condition.value" :multiple="condition.compare == 'in'" isAll></select-depart>
                </template>
                <template v-else-if="condition.valueType == 'select' || condition.valueType == 'radio'">
                  <a-select v-model="condition.value[0]">
                    <a-select-option :value="v.value" v-for="v in condition.options" :key="v.value" :label="v.label">{{
      v.label
    }}</a-select-option>
                  </a-select>
                </template>
                <a-input style="width:100%" placeholder="输入比较值" v-model="condition.value[0]" v-else />
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orgPicker from '@/components/process/common/organizationPicker';
import ValueType from '@/components/process/process/ConditionVarType';
import utils from '@/common/utils';

export default {
  name: "ConditionGroupItemConfig",
  components: { orgPicker },
  data() {
    return {
      ValueType,
      users: [],
      //groupConditions: [],
      groupNames: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      supportTypes: [ValueType.number, ValueType.string, ValueType.date, ValueType.dept, ValueType.user],
      explains: [
        { label: '等于', value: '=' },
        { label: '大于', value: '>' },
        { label: '大于等于', value: '>=' },
        { label: '小于', value: '<' },
        { label: '小于等于', value: '<=' },
        { label: '包含在', value: 'IN' },
        { label: 'x < 值 < x', value: 'B' },
        { label: 'x ≤ 值 < x', value: 'AB' },
        { label: 'x < 值 ≤ x', value: 'BA' },
        { label: 'x ≤ 值 ≤ x', value: 'ABA' },
      ]
    };
  },
  computed: {
    selectedNode() {
      return this.$store.state.flow.selectedNode;
    },
    conditionList() {
      const conditionItems = [];
      this.$store.state.flow.design.formItems.forEach(f => {
        const options = f.options.options ? f.options.options : [];
        conditionItems.push({ title: f.label, id: f.key, valueType: f.type, options: options });

      });
      // if (conditionItems.length === 0 || conditionItems[0].id !== 'root') {
      //   conditionItems.unshift({ id: 'root', title: '发起人', valueType: 'staff' })
      // }
      return conditionItems;
    }
  },
  methods: {
    conditionValType(type) {
      switch (type) {
        case '=':
        case '>':
        case '>=':
        case '<':
        case '<=': return 0;
        case 'IN': return 1;
        default: return 2;
      }
    },
    //包含条件
    isIn(type) {
      return false;
    },
    //大于条件
    isGt(type) {
      switch (type) {
        case 'number':
        case 'calc':
        case 'select':
        case 'radio': return true;
        default: return false;
      }
    },
    //小于条件
    isLt(type) {
      switch (type) {
        case 'number':
        case 'calc':
        case 'select':
        case 'radio': return true;
        default: return false;
      }
    },
    delGroup(index) {
      this.selectedNode.props.groups.splice(index, 1);
    },
    conditionChange(group) {
      //判断新增的
      group.cids.forEach(cid => {
        const condition = this.findCondition(cid);
        if (group.conditions.findIndex(cd => cd.id == condition.id) < 0) {
          condition.compare = '';
          condition.value = [];
          group.conditions.push(JSON.parse(JSON.stringify(condition)));
        }
      });
    },
    //
    findCondition(cid) {
      const list = this.conditionList.filter(item => {
        if (item.id == cid) {
          return item;
        }
      });
      return utils.isEmpty(list) ? null : list[0];
    }
  }
};
</script>

<style lang="less" scoped>
.group {
  margin-bottom: 20px;
  color: #5e5e5e;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e3e3e3;

  .group-header {
    padding: 5px 10px;
    background: #e3e3e3;
    position: relative;

    div {
      display: inline-block;
    }

    .group-name {
      font-size: small;
    }

    .group-cp {
      font-size: small;
      position: absolute;
      left: 100px;
      display: flex;
      top: 5px;
      justify-content: center;
      align-items: center;
    }

    .group-operation {
      position: absolute;
      right: 10px;

      i {
        padding: 0 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .group-content {
    padding: 10px 10px;

    p {
      text-align: center;
      font-size: small;
    }
  }

  .condition-title {
    display: block;
    width: 100px;
  }
}
</style>
