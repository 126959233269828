<template>
  <div>
    <a-table :header-cell-style="{ background: '#f5f6f6' }" :data-source="formPerms" :pagination="false" :border="false"
             size="small" row-key="id" style="width: 100%">
      <a-table-column prop="title" show-overflow-tooltip align="left" width="100%">
        <div slot="title">
          <div @click="Sort">
            <div style="display: flex;align-items: center;">
              <div>表单字段</div>
              <div style="display: flex;flex-direction: column;">
                <a-icon type="caret-up" style="margin-bottom: -6px;" :style="{ color: sort ? '#1890ff' : '' }"></a-icon>
                <a-icon type="caret-down" :style="{ color: !sort ? '#1890ff' : '' }"></a-icon>
              </div>
            </div>
          </div>
        </div>
        <template slot-scope="column">
          <span>{{ column.title }}</span>
        </template>
      </a-table-column>
      <a-table-column prop="readOnly" width="80" align="left">
        <div slot="title">
          <a-radio-group v-model="permSelect" @change="allSelect('R')">
            <a-radio value="R">只读</a-radio>
          </a-radio-group>
        </div>
        <template slot-scope="column">
          <a-radio-group v-model="column.perm">
            <a-radio value="R"></a-radio>
          </a-radio-group>
        </template>
      </a-table-column>
      <a-table-column prop="editable" width="80" align="left">
        <div slot="title">
          <a-radio-group v-model="permSelect" @change="allSelect('E')">
            <a-radio value="E">可编辑</a-radio>
          </a-radio-group>
        </div>
        <template slot-scope="column">
          <a-radio-group v-model="column.perm">
            <a-radio value="E"></a-radio>
          </a-radio-group>
        </template>
      </a-table-column>
      <a-table-column prop="hide" width="80" align="left">
        <div slot="title">
          <a-radio-group v-model="permSelect" @change="allSelect('H')">
            <a-radio value="H">隐藏</a-radio>
          </a-radio-group>
        </div>
        <template slot-scope="column">
          <a-radio-group v-model="column.perm">
            <a-radio value="H"></a-radio>
          </a-radio-group>
        </template>
      </a-table-column>

    </a-table>
  </div>
</template>

<script>
export default {
  name: "FormAuthorityConfig",
  components: {},
  data() {
    return {
      sort: false,
      tableData: [],
      isIndeterminate: false,
      permSelect: '',
      checkStatus: {
        readOnly: true,
        editable: false,
        hide: false
      },
      cloumn: [
        {
          title: '表单字段', dataIndex: 'title', align: 'left', sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        { title: '只读', scopedSlots: { customRender: 'readOnly' }, align: 'center', width: 100, ellipsis: true },
        { title: '编辑', scopedSlots: { customRender: 'editable' }, align: 'center', width: 100, ellipsis: true },
        { title: '隐藏', scopedSlots: { customRender: 'hide' }, align: 'center', width: 100, ellipsis: true },

      ]
    };
  },
  created() {
    this.checkForm();
  },
  computed: {
    formData() {
      return this.$store.state.flow.design.formItems;
    },
    formPerms() {
      return this.$store.state.flow.selectedNode.props.formItems;
    },

  },
  methods: {
    Sort() {
      this.sort = !this.sort;
      if (!this.sort) {
        this.$store.state.flow.selectedNode.props.formItems.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      } else {
        this.$store.state.flow.selectedNode.props.formItems.sort((a, b) => {
          return b.title.localeCompare(a.title);
        });
      }
    },
    checkForm() {
      const saveList = [];
      const list = this.$store.state.flow.selectedNode.props.formItems;
      list.forEach((item, i) => {
        let is = false;
        for (let j in this.formData) {
          if (this.formData[j].key == item.id) {
            saveList.push(item);
            break;
          }
        }
      });
      this.$store.state.flow.selectedNode.props.formItems = saveList;
      this.formPermsLoad();
    },
    allSelect(type) {
      this.permSelect = type;
      this.formPerms.forEach(f => f.perm = type);
      this.permSelect = type;
    },
    formPermsLoad() {
      let perms = this.$store.state.flow.selectedNode.props.formItems;
      this.formData.forEach(form => {
        let isLoad = false;
        for (let i in this.formPerms) {
          if (perms[i].id === form.key) {
            perms[i].title = form.label;
            isLoad = true;
            break;
          }
        }
        if (!isLoad) {
          perms.push({
            id: form.key,
            title: form.label,
            perm: this.$store.state.flow.selectedNode.type === 'ROOT' ? 'E' : 'R'
          });
        }
      });
    },
    handleCheckAllChange() {

    }
  },
  watch: {
    formPerms: {
      deep: true,
      handler() {
        const set = new Set(this.formPerms.map(f => f.perm));
        if (set.size > 1) {
          this.permSelect = '';
        } else {
          const types = Array.from(set);
          this.permSelect = types[0];
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row {
  &>td:first-child {
    .cell {
      text-align: left;
    }
  }

  .cell {
    text-align: center;
  }

  .a-radio__label {
    display: none;
  }
}
</style>
