<template>
  <a-popover title="添加流程节点" width="350" v-model="visible" trigger="click">
    <div class="node-select" slot="content">
      <div @click="addApprovalNode">
        <a-icon type="audit" style="color:rgb(255, 148, 62);"></a-icon>
        <span>审批节点</span>
      </div>
      <!-- <div @click="addCcNode">
        <i class="el-icon-s-promotion" style="color:rgb(50, 150, 250);"></i>
        <span>抄送人</span>
      </div> -->
      <div @click="addConditionsNode">
        <!-- <i class="el-icon-share" style="color:rgb(21, 188, 131);"></i> -->
        <a-icon type="fork" style="color:rgb(21, 188, 131);"></a-icon>
        <span>条件分支</span>
      </div>
      <!-- <div @click="addConcurrentsNode">
        <a-icon type="bars" style="color:#718dff;"></a-icon>
        <span>并行分支</span>
      </div> -->
      <!-- <div @click="addDelayNode">
        <i class="el-icon-time" style="color:#f25643;"></i>
        <span>延迟等待</span>
      </div>
      <div @click="addTriggerNode">
        <i class="el-icon-set-up" style="color:#15BC83;"></i>
        <span>触发器</span>
      </div> -->
    </div>
    <a-button icon="plus" shape="circle" type="primary" size="small"></a-button>
  </a-popover>
</template>

<script>
export default {
  name: "InsertButton",
  components: {},
  data() {
    return {
      visible: false
    }
  },
  computed: {
    selectedNode() {
      this.$store.state.flow.selectedNode
    }
  },
  methods: {
    addApprovalNode() {
      this.$emit('insertNode', "APPROVAL")
      this.visible = false;
    },
    addCcNode() {
      this.$emit('insertNode', "CC")
      this.visible = false;
    },
    addDelayNode() {
      this.$emit('insertNode', "DELAY")
      this.visible = false;
    },
    addConditionsNode() {
      this.$emit('insertNode', "CONDITIONS")
      this.visible = false;
    },
    addConcurrentsNode() {
      this.$emit('insertNode', "CONCURRENTS")
      this.visible = false;
    },
    addTriggerNode() {
      this.$emit('insertNode', "TRIGGER")
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.node-select {
  div {
    display: inline-block;
    margin: 5px 5px;
    cursor: pointer;
    padding: 10px 15px;
    border: 1px solid #F8F9F9;
    background-color: #F8F9F9;
    border-radius: 10px;
    width: 130px;
    position: relative;

    span {
      position: absolute;
      left: 65px;
      top: 18px;
    }

    &:hover {
      background-color: #fff;
      box-shadow: 0 0 8px 2px #d6d6d6;
    }

    i {
      font-size: 25px;
      padding: 5px;
      border: 1px solid #dedfdf;
      border-radius: 14px;
    }
  }
}
</style>
